import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from "gatsby-plugin-mdx"
import FullLogo from '../components/devmastery/FullLogo'
import {ThemeContext} from '../context/ThemeContextProvider'
import * as styles from './project.module.css'
import ToggleThemeIcon from '../components/ToggleThemeIcon'

type TypePost = {
    id?: string
    body: string
    frontmatter: {
        title: string
    }
    slug: string
  }

type TypeData = {
    mdx: TypePost
}

type PropsType = {
    data: TypeData
}

function project({data}:PropsType) {
    const {themeIsDark, setTheme} = React.useContext(ThemeContext)
    const handleClick = () => {
        setTheme(!themeIsDark)
    }
    return (
        <div className={`${styles.project}`}>
            <header className="container">
            <FullLogo height="100%" />
            <ToggleThemeIcon themeIsDark={themeIsDark} onClick={handleClick} style={{cursor: 'pointer'}} />
            </header>

            <div className={`${styles.project_content} container`}>
                <h1 className="text-center">{data.mdx.frontmatter.title}</h1>
                <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </div>
        </div>
    )
}

export default project


export const query = graphql`
    query MyQuery($slug: String) {
        mdx(slug: {eq: $slug}) {
            body
            frontmatter {
            title
            }
        }
    }
`
